import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';

@Component({
  components: {}
})
export default class AttendanceDetailsSideNav extends Vue {
    private objScreenText: ScreenText = new ScreenText();


    @Prop() currectSelectedRecord!: any; 

    @Watch('currectSelectedRecord',{deep:true,immediate:true})
    changeScholarGuardians(val: any) {  
      this.currectSelectedRecord= val;  
    }

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    formatDate(date: string) {
      return APP_UTILITIES.formatShortDate(date).split('/').join('-');
    }

    formatPhoneNo(phoneNumber: string){
      return phoneNumber && APP_UTILITIES.formatNumber(phoneNumber);
    }
}